import React, { useContext } from 'react'
import Context from '../contexts/Context'
import TestContext from '../App.js'
import { getDefaultNormalizer } from '@testing-library/react';
import { createUseStyles } from 'react-jss'

import BButton from 'react-bootstrap/Button';
import MButton from "@mui/material/Button";

import map from './../images/karte.jpg';

const useStyles = createUseStyles({
	Image: {
	  width: "100%",
	  height: "100%",
	}
  });

export const Test = ({count, setCount, ...props}) => {
	const classes = useStyles();

	// const { value_a, url } = useContext(Context)
	// console.log("use context", useContext);
	// console.log("useContext(Context)", useContext(Context));
	// console.log("Context", Context);

	// console.log("TestContext", TestContext)

	return (
		<>
		<div>
			<button onClick={() => {setCount(count + 2);}}>blyat</button>
		</div>
		<div>
					<BButton variant="primary">Button #1</BButton>
		</div>
		<div>
					<MButton variant="contained">Contained</MButton>
		</div>

		<svg height="100" width="100">
			<circle cx="50" cy="50" r="40" stroke="black" strokeWidth={count} fill="red" />
			Sorry, your browser does not support inline SVG.
		</svg>

		<div>
			<img src={map} className={classes.Image} alt="this is car image" />
        </div>
		</>
	);
};