import Unity, { UnityContext } from "react-unity-webgl";

const unityContext = new UnityContext({
  loaderUrl: "bremenquiz/Build/01.loader.js",
  dataUrl: "bremenquiz/Build/01.data",
  frameworkUrl: "bremenquiz/Build/01.framework.js",
  codeUrl: "bremenquiz/Build/01.wasm",
});

const BremenQuizUnity = () => {
  return <Unity
    unityContext={unityContext}
    style={{
      height: "90%",
      width: '90%',
      border: "2px solid black",
      background: "grey",
    }} />
}

export default BremenQuizUnity;