import * as React from "react";
import { Routes, Route, Link } from "react-router-dom";
import { createUseStyles } from 'react-jss'

// Styling
import "./App.css";

// Contexts
import ContextProvider from './contexts/Context'

// Components
import Home from "./components/Home.js";
import About from "./components/About.js";
import Imprint from "./components/Imprint.js";
import P5 from "./components/P5.js";
import BremenQuizUnity from "./components/BremenQuizUnity.js";
import {Test} from "./components/Test.js";
import { Container } from "@mui/material";

const useStyles = createUseStyles({
  LinkRed: {
    color: "black",
    paddingRight: "1em",
  }
});

function NavLink({ to, name }) {
  const classes = useStyles();

  return (
    <>
    <b>
      <Link
        to={to}
        className={classes.LinkRed}
        >
        {name}
      </Link>
    </b>
    </>
  );
}

function App() {

  console.log("App");

  const [count, setCount] = React.useState(0);
  const classes = useStyles();

  const routes = [
    { to: "/", name: "Home", component: <Home/> },
    // { to: "/test", name: "Test", component: <Test/> },
    // { to: "/about", name: "About", component: <About/> },
    { to: "/imprint", name: "Imprint", component: <Imprint/> },
    // { to: "/p5", name: "P5", component: <P5/> },
    // { to: "/bremenquiz", name: "Bremen Quiz", component: <BremenQuizUnity/> },
  ];

  return (
    <Container className="App">

      <nav>
        {routes.map(route => (
          <NavLink key={route.to} to={route.to} name={route.name} />
        ))}
        <a href="/bremenquiz" target="_blank" className={classes.LinkRed}><b>Bremen Quiz</b></a>
      </nav>

      <hr/>

      {/* ### Button Testing ### */}
      {/*
      <Link to="/"><button>Home</button></Link>
      <button onClick={() => {setCount(count + 1); console.log("Reeeeee!!");}}>Reeeeee!!</button>
      <hr/>
      <div>
        {count > 0 ? <i>i greater 0</i> : <i>i less/equal 0</i>}
      </div>
      <div>
        <b>{count}</b>
      </div>
      <hr/>
      */}
      {/* ~~~ Button Testing ~~~ */}

      {/* ### Context Testing ### */}
      {/*
      <ThemeContext.Provider value="dark">
        <Toolbar />
      </ThemeContext.Provider>
      <hr/>
      */}
      {/* ~~~ Context Testing ~~~ */}

      <Routes>
        {/* Doesn't work cause elements need to be wrapped in curly braces etc. */}
        {routes.map(route => (
          <Route key={route.to} path={route.to} element={route.component} />
        ))}
      </Routes>
    </Container>
  );
}


const ThemeContext = React.createContext('light');


// A component in the middle doesn't have to
// pass the theme down explicitly anymore.
function Toolbar() {
  return (
    <div>
      <ThemedButton />
    </div>
  );
}

class ThemedButton extends React.Component {
  // Assign a contextType to read the current theme context.
  // React will find the closest theme Provider above and use its value.
  // In this example, the current theme is "dark".
  static contextType = ThemeContext;
  render() {
    return <h1>{this.context}</h1>;
  }
}

export default App;
